import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Reports } from 'services/reports';
import { AGENT_METRIC_TYPE } from 'common/constants';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import moment from 'moment';

export class ByAgentMetrics extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, Reports];
    @bindable({ changeHandler: '_inputChanged' }) chartType;
    @bindable({ changeHandler: '_inputChanged' }) metricType;
    @bindable({ changeHandler: '_inputChanged' }) dateType;
    @bindable({ changeHandler: '_inputChanged' }) timePeriod;
    @bindable({ changeHandler: '_inputChanged' }) policyStatusGroup;
    @bindable({ changeHandler: '_inputChanged' }) premiumType;
    @bindable({ changeHandler: '_inputChanged' }) linesOfBusiness;
    @bindable sync = true;
    @bindable scroll = true;

    _reports;
    currentPeriod;
    AGENT_METRIC_TYPE = AGENT_METRIC_TYPE;

    constructor(ea, reports) {
        super('by-agent-metrics', ea);
        this._reports = reports;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
    }

    async _inputChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async refreshData() {
        this.refreshing = true;
        if (this.report) this.report = undefined;
        await this._loadData();
        this.refreshing = false;
    }

    async _loadData(fromSync = false) {
        try {
            if (!this._reports.validateByAgentMetrics(this.timePeriod, this.metricType, this.linesOfBusiness, this.dateType, this.policyStatusGroup, this.premiumType)) return;
            this.setReportTitle();
            this.loading = true;

            this.initializeTimePeriodNavigation(fromSync);

            this.metrics = await this._reports.byAgentMetrics(this.asOfDate, this.timePeriod, this.metricType, this.linesOfBusiness, this.dateType, this.policyStatusGroup, this.premiumType);

            this.currentPeriod = this.metrics.period;
            this.setTimePeriodNavigationDisplays();

            const chartData = [];
            this.metrics.records.forEach(r => {
                chartData.push({ id: r.agent.id, name: r.agent.fullName, shortName: r.agent.initials, value: r.value });
            });
            this.chartData = chartData;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    setReportTitle() {
        switch (this.metricType) {
            case AGENT_METRIC_TYPE.Count:
                this.titleKey = 'apps';
                break;

            case AGENT_METRIC_TYPE.PolicyStatusGroup:
                this.titleKey = `policy-status-group-${this.policyStatusGroup}`;
                break;
            
            case AGENT_METRIC_TYPE.Premium:
                this.titleKey = this.premiumType;
                break;

            case AGENT_METRIC_TYPE.Prospect:
                this.titleKey = 'prospects';
                break;
    
            case AGENT_METRIC_TYPE.RegisteredRep:
                this.titleKey = 'registered-reps';
                break;
        
            case AGENT_METRIC_TYPE.Recruit:
                this.titleKey = 'recruits';
                break;

        }
    }

    async drilldown(drilldownDetail) {
        try {
            if (!drilldownDetail) return;
            this.agentName = drilldownDetail.name;
            this.loadingDetails = true;
            this.agentDetails = await this._reports.byAgentMetrics(this.asOfDate, this.timePeriod, this.metricType, this.linesOfBusiness, this.dateType, this.policyStatusGroup, this.premiumType, drilldownDetail.id);
            this.showDetails();
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingDetails = false;
        }
    }

    async showDetails() {
        this.showDrawer = moment().format();
    }

    drawerClosed() {
    }
}
