import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { ProductionService } from 'services/production-service';
import { WIDGET } from 'common/constants';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';

export class VolumeRankings extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, I18n, ProductionService];
    _i18n;
    _production;

    @bindable settings;
    @bindable sync = true;
    @bindable scroll = true;

    leaderboard;
    asOfDate;
    currentPeriod;

    constructor(ea, i18n, production) {
        super('volume-rankings', ea);
        this._i18n = i18n;
        this._production = production;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
    }

    settingsChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async _loadData(fromSync = false) {
        if (!this.settings) return;
        try {
            this.reportForTr = this._i18n.tr(`report-for-${this.reportFor}`);
            this.loading = true;
            this.leaderboard = undefined;
            this.initializeTimePeriodNavigation(fromSync);

            this.leaderboard = await this._production.volumeRankings(this.settings, this.asOfDate.toISOString(true));

            this.timePeriod = WIDGET.Period.toReportTimePeriod(this.settings.period);
            this.currentPeriod = this.leaderboard.timePeriod;
            this.setTimePeriodNavigationDisplays();
            this.priorPeriod1Heading = this.getTimePeriodDisplay(this.leaderboard.priorTimePeriod1);
            this.priorPeriod2Heading = this.getTimePeriodDisplay(this.leaderboard.priorTimePeriod2);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
