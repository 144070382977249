import { bindable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SUMMARY_TYPE, ProductionService } from 'services/production-service';
import { Security } from 'common/security';
import { Members } from 'services/members';
import { I18n } from 'common/i18n';
import { TIME_PERIOD, ROLE } from 'common/constants';
import { c } from 'common/common';
import moment from 'moment';

export class ProductionSummary {
    static inject = [EventAggregator, ProductionService, Security, Members, I18n];
    @bindable memberId = null;
    @bindable compact = true;
    @bindable scroll = true;
    @bindable timePeriod = TIME_PERIOD.YTD;
    @bindable summaryType = SUMMARY_TYPE.Personal;
    @bindable date = moment();
    @bindable friendlyName;

    _ea;
    _productionService;
    _security;
    _members;

    TIME_PERIOD = TIME_PERIOD;
    SUMMARY_TYPE = SUMMARY_TYPE;
    policyFilters = [{ value: '', keys: ['submittedDate', 'client.fullName', 'writingAgent.fullName', 'policyNumber', 'carrier.name', 'carrier.code', 'submittedPremium', 'status'] }];
    agentSummaryFilters = [{ value: '', keys: ['writingAgent.fullName', 'submittedPremium', 'paidPremium', 'placement.percentage'] }];
    policyPremiumType = 'submitted';

    showPlacementPercentage = false;
    policyListType = 'submitted';

    constructor(ea, productionService, security, members, i18n) {
        this._ea = ea;
        this._productionService = productionService;
        this._security = security;
        this._members = members;
        this._i18n = i18n;
    }

    attached() {
        this.isAttached = true;
        this._load();
    }

    @computedFrom('summaryType', 'friendlyName')
    get summaryTypeName() {
        if (this.friendlyName) return this.friendlyName;
        if (!this.summaryType) return '';
        return this._i18n.tr(`production-summary-${this.summaryType}`);
    }

    memberIdChanged() {
        if (!this.isAttached) return;
        this._load();
    }

    async _load() {
        try {
            this.loading = true;
            this.summary = undefined;
            this.policies = undefined;
            this.showAgencyOption = false;
            this.showMasterAgencyOption = false;

            // Handle bindables where nothing was set
            this.date = this.date || moment();
            this.timePeriod = this.timePeriod || TIME_PERIOD.YTD;
            this.summaryType = this.summaryType || SUMMARY_TYPE.Personal;

            this.summary = await this._productionService.summary(this.memberId, this.date, this.timePeriod, this.summaryType);
            this._setTimePeriodButtons();

            this.showPlacementPercentage = this.timePeriod === TIME_PERIOD.YTD || this.timePeriod === TIME_PERIOD.MTD;

            this.viewPolicies(this.policyListType);
            const inRoleToViewAgencyOption = this._security.isInRole([ROLE.Admin, ROLE.HierarchyViewer]);
            this.showAgencyOption = (inRoleToViewAgencyOption || this._security.isAgency()) && this.summary.member.isAgency;
            if (inRoleToViewAgencyOption || this._security.isMasterAgency()) {
                const ma = await this._members.masterAgency(this.summary.member.id);
                if (ma !== null) {
                    this.showMasterAgencyOption = ma.id === this.summary.member.id;
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    selectTimePeriod(timePeriod) {
        this.timePeriod = timePeriod;
        this.date = moment();
        this._setTimePeriodButtons();

        this._load();
    }

    previousTimePeriod() {
        this._setNextTimePeriodDate(-1);
    }

    nextTimePeriod() {
        if (this.noNextTimePeriod) return;
        this._setNextTimePeriodDate(1);
    }

    _setNextTimePeriodDate(addDays) {
        // When moving to the next time period, add 1 day to the end of the current period
        // When moving to the previous time period, subtract 1 day from the current period
        const baseDate = addDays > 0 ? this.summary.endDate : this.summary.startDate;
        this.date = moment(baseDate).add(addDays, 'days');
        this._setTimePeriodButtons();
        this._load();
    }

    _setTimePeriodButtons() {
        this.noNextTimePeriod = moment(this.summary.endDate).isAfter(moment());
        switch (this.summary.timePeriod){
            case 'mtd': this.timePeriodDisplay = moment.utc(this.summary.endDate).format('MMMM'); break;
            case 'wtd': this.timePeriodDisplay = `${moment(this.summary.startDate).format('l')} - ${moment(this.summary.endDate).format('l')}`; break;
            case 'ytd': this.timePeriodDisplay = moment(this.summary.endDate).format('YYYY'); break;
            default: this.timePeriodDisplay = `${moment(this.summary.startDate).format('l')} - ${moment(this.summary.endDate).format('l')}`; break;
        }
    }

    setType(type) {
        this.summaryType = type;
        this.policyListType = type === SUMMARY_TYPE.Personal ? 'submitted' : undefined;
        this._load();
    }

    viewPolicies(type) {
        this.loading = true;
        this.policyListType = type;
        this.policies = [];
        this.policyPremiumType = type;

        let policies;
        switch (this.policyListType) {
            case 'submitted':
                policies = this.summary.policies;
                break;
            case 'paid':
                const allPolicies = [...this.summary.policies, ...this.summary.paidPoliciesNotSubmittedInTimePeriod];
                policies = allPolicies.filter(x => this.summary.paid.policyIds.includes(x.id));
                break;
            case 'pending':
                policies = this.summary.policies.filter(x => x.category === 'pending');
                this.policyPremiumType = 'submitted';
                break;
            default:
                policies = undefined;
                this.policyPremiumType = 'submitted';
                break;
        }
        this.policies = policies;
        this.loading = false;
    }

    returnToSummaries() {
        this.policies = undefined;
    }

    togglePlacementDetails() {
        this.showPlacementDetails = !this.showPlacementDetails;
        this.showDrawer = moment().format();
    }

    toggleMiniPolicySummary(ev, policy) {
        ev.stopPropagation();
        ev.preventDefault();
        policy.showMiniSummary = !policy.showMiniSummary;
        return false;
    }

    policyNoteUpdated(policy) {
        policy.mostRecentNoteDate = moment().toISOString();
    }

    showPolicyDetails(policy) {
		this._ea.publish(c.EventKeys.site.openPolicy, { policyId: policy.id });
    }

    rowDetailsClicked(event) {
        event.stopPropagation();
        return false;
    }

    
    addComment(ev, policy) {
        ev.stopPropagation();
        ev.preventDefault();
        this.policy = policy;
        this.addPolicyNote = true;
        this.showPolicySummary = false;
        this.showDrawer = moment().format();
    }

    closePolicyNote() {
        this.policy.mostRecentNoteDate = moment().toISOString();
        this.addPolicyNote = false;
        this.showDrawer = undefined;
    }

    @computedFrom('summaryType', 'date', 'timePeriod')
    get profileCardTab() {
        const summaryType = this.summaryType === SUMMARY_TYPE.Agency
            ? SUMMARY_TYPE.Team
            : SUMMARY_TYPE.Personal;
        return { key: 'production', model: { date: this.date, timePeriod: this.timePeriod, summaryType }};
    }

    drawerClosed() {
        this.showPlacementDetails = false;
        this.addPolicyNote = false;
        this.policy = undefined;
    }
}
