import { bindable } from 'aurelia-framework';
import { Members } from 'services/members';
import { Security } from 'common/security';
import { EventAggregator } from 'aurelia-event-aggregator';
import { c } from 'common/common';

export class ProfileCard {
    static inject = [Members, Security, EventAggregator];
    _members;
    _security;
    _ea;

    @bindable forId;
    @bindable type;
    @bindable card = 'full';
    @bindable member;
    @bindable showBadges = false;
    @bindable reload;
    @bindable size = 'xl';

    cardTitleKey;
    profile;
    canViewProfile = false;

    constructor(members, security, ea) {
        this._members = members;
        this._security = security;
        this._ea = ea;
    }

    reloadChanged() {
        if (this.forId) {
            this._load();
        }
    }

    memberChanged() {
        this._loadMember();
    }

    async _loadMember() {
        try {
            this.clear();
            if (!this.member) return;
            this.cardTitleKey = 'agent';
            this.profile = this.member;
            this.canViewProfile = await this._members.canAccessProfile(this.profile.id);
        } catch (err) {
            console.log(err);
        }
    }

    forIdChanged() {
        if (!this.forId) {
            this.clear();
            return;
        }
        this._load();
    }

    typeChanged() {
        this._load();
    }

    clear() {
        this.cardTitleKey = undefined;
        this.profile = undefined;
        this.canViewProfile = false;
    }

    async _load() {
        if (!this.type || !this.forId) return;
        try {
            this.clear();
            if (this.type === 'direct-upline') {
                this.cardTitleKey = 'direct-upline';
                this.profile = await this._members.getDirectUpline(this.forId);
            } else if (this.type === 'agency') {
                this.cardTitleKey = 'agency';
                this.profile = await this._members.getAgency(this.forId);
            } else if (this.type === 'mentor') {
                this.cardTitleKey = 'mentor';
                this.profile = await this._members.getMentor(this.forId);
            } else {
                this.cardTitleKey = 'agent';
                this.profile = await this._members.getMember(this.forId);
            }
            if (!this.profile) return;
            this.canViewProfile = await this._members.canAccessProfile(this.profile.id);
        } catch (err) {
            console.log(err);
        }
    }


    openProfile(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        this._ea.publish(c.EventKeys.site.openProfile, { memberId: this.profile.id });
    }
}
