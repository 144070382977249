import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Reports } from 'services/reports';
import { METRIC_TYPE } from 'common/constants';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';

export class ByCarrierMetrics extends TimePeriodNavigatorBase {
    static inject = [EventAggregator, Reports];
    @bindable({ changeHandler: '_inputChanged' }) chartType;
    @bindable({ changeHandler: '_inputChanged' }) metricType;
    @bindable({ changeHandler: '_inputChanged' }) dateType;
    @bindable({ changeHandler: '_inputChanged' }) timePeriod;
    @bindable({ changeHandler: '_inputChanged' }) policyStatusGroup;
    @bindable({ changeHandler: '_inputChanged' }) premiumType;
    @bindable sync = true;
    @bindable scroll = true;

    _reports;
    currentPeriod;
    METRIC_TYPE = METRIC_TYPE;

    constructor(ea, reports) {
        super('by-carrier-metrics', ea);
        this._reports = reports;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
    }

    async _inputChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async refreshData() {
        this.refreshing = true;
        if (this.report) this.report = undefined;
        await this._loadData();
        this.refreshing = false;
    }

    async _loadData(fromSync = false) {
        try {
            if (!this._reports.validateByCarrierMetrics(this.timePeriod, this.metricType, this.dateType, this.policyStatusGroup, this.premiumType)) return;
            this.setReportTitle();
            this.loading = true;

            this.initializeTimePeriodNavigation(fromSync);

            this.metrics = await this._reports.byCarrierMetrics(this.asOfDate, this.timePeriod, this.metricType, this.dateType, this.policyStatusGroup, this.premiumType);

            this.currentPeriod = this.metrics.period;
            this.setTimePeriodNavigationDisplays();

            const chartData = [];
            this.metrics.records.forEach(r => {
                chartData.push({ id: r.carrier.id, name: r.carrier.name, shortName: r.carrier.code, value: r.value });
            });
            this.chartData = chartData;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    setReportTitle() {
        switch (this.metricType) {
            case METRIC_TYPE.Count:
                this.titleKey = 'apps';
                break;

            case METRIC_TYPE.PolicyStatusGroup:
                this.titleKey = `policy-status-group-${this.policyStatusGroup}`;
                break;
            
            case METRIC_TYPE.Premium:
                this.titleKey = this.premiumType;
                break;
        }
    }
}
