import { bindable } from 'aurelia-framework';

export class ProfileName {
    @bindable profile;
    @bindable nameLink;
    initials;

    constructor() { }

    profileChanged() {
        try {
            this.initials = '';
            if (!this.profile) return;
            if (!this.profile.firstName && !this.profile.lastName) return;
            if (this.profile.firstName) this.initials += this.profile.firstName.substr(0, 1);
            if (this.profile.lastName) this.initials += this.profile.lastName.substr(0, 1);
        } catch (err) {
            console.log(err);
        }
    }
}
