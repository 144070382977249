import { bindable } from 'aurelia-framework';
import { ProductionService } from 'services/production-service';
import { c } from 'common/common';

export class PlacementAndPersistency {
    static inject = [ProductionService];

    @bindable memberId;

    _production;

    constructor(production) {
        this._production = production;
        this.id = c.Helpers.uniqueId();
    }

    memberIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.memberId) return;
        try {
            this.loading = true;
            const data = await this._production.placementAndPersistency(this.memberId);
            if (data == null) {
                this.data = null;
                return;
            }
            this.placementBreaks = [data.placement.targetPercentage, data.placement.targetPercentage];
            this.persistency3mBreaks = [data.persistency3Month.targetPercentage, data.persistency3Month.targetPercentage];
            this.persistency6mBreaks = [data.persistency6Month.targetPercentage, data.persistency6Month.targetPercentage];
            this.persistency9mBreaks = [data.persistency9Month.targetPercentage, data.persistency9Month.targetPercentage];
            this.persistency13mBreaks = [data.persistency13Month.targetPercentage, data.persistency13Month.targetPercentage];
            this.data = data;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
