import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { CACHE_TYPE, CACHE_PERIOD, ProductionService } from 'services/production-service';

export class ProductionStatus {
    static inject = [Element, Security, ProductionService];
    @bindable({ changeHandler: '_reload' }) memberId = null;
    @bindable({ changeHandler: '_reload' }) cachePeriod;
    @bindable({ changeHandler: '_reload' }) cacheType;
    @bindable friendlyName;
    @bindable showTitle = true;
    @bindable horizontal = false;
    @bindable size;

    _element;
    _security;
    _productionService;

    CACHE_PERIOD = CACHE_PERIOD;
    CACHE_TYPE = CACHE_TYPE;

    constructor(element, security, productionService) {
        this._element = element;
        this._security = security;
        this._productionService = productionService;
    }

    attached() {
        this.isAttached = true;
        this._load();
    }

    _reload() {
        if (!this.isAttached) return;
        this._load();
    }

    async _load() {
        try {
            this.loading = true;

            // Handle bindables where nothing was set
            this.cachePeriod = this.cachePeriod || CACHE_PERIOD.Yesterday;
            this.cacheType = this.cacheType || CACHE_TYPE.Personal;
            const memberId = this.memberId || this._security.authenticatedMemberId;
            this.status = await this._productionService.status(memberId, this.cacheType, this.cachePeriod);
            this._element.dispatchEvent(new CustomEvent('loaded', { bubbles: true, detail: { period: this.status?.period } }));
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    statusDirection(difference) {
        try {
            if (difference > 0) return 'up';
            if (difference < 0) return 'down';
            return 'even';
        } catch (err) {
            console.log(err);
        }
    }

    clicked(type) {
        this._element.dispatchEvent(new CustomEvent('clicked', { bubbles: true, detail: { dateType: type } }));
    }
}
