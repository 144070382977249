import { bindable } from 'aurelia-framework';
import { createPopper } from '@popperjs/core';

export class Popover {
    static inject = [Element];
    _element;
    @bindable forId;
    @bindable offsetX = 8;
    @bindable offsetY = 8;
    @bindable strategy;
    @bindable update;
    @bindable placement = 'auto'
    @bindable close;

    _forEl;

    constructor(element) {
        this._element = element;
    }

    attached() {
        this._initialize();
    }

    detached() {
        try {
            if (!this._popper) return;
            this._popper.destroy();
        } catch (err) {
            console.log(err);
        }
    }

    _initialize() {
        try {
            this.forEl = document.getElementById(this.forId);
            if (!this.forEl) return;
            const options = {
                placement: this.placement,
                container: 'body',
                strategy: this.strategy || 'absolute',
                modifiers: [
                    {
                        name: 'offset',
                        options: { offset: [Number(this.offsetX), Number(this.offsetY)] },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: document.getElementById('kt_app_content')
                        }
                    }
                ]
            };
            this._popper = createPopper(this.forEl, this.popoverEl, options);
            const showEvents = ['click'];
            const hideEvents = [];

            showEvents.forEach(e => this.forEl.addEventListener(e, this._show));
            hideEvents.forEach(e => this.forEl.addEventListener(e, this._hide));
        } catch (err) {
            console.log(err);
        }
    }

    _show = () => {
        try {
            this.popoverEl.setAttribute('data-show', '');
            this._popper.setOptions((options) => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: true },
                ]
            }));
            this._popper.update();
            this._element.dispatchEvent(new CustomEvent('opened', { bubbles: true }));
        } catch (err) {
            console.log(err);
        }
    }

    closeChanged() {
        if (!this.close) return;
        this._hide();
    }

    hide(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        this._hide();
    }

    _hide = () => {
        try {
            this.popoverEl.removeAttribute('data-show');
            this._popper.setOptions((options) => ({
                ...options,
                modifiers: [
                  ...options.modifiers,
                  { name: 'eventListeners', enabled: false },
                ],
              }));
            this._element.dispatchEvent(new CustomEvent('closed', { bubbles: true }));
        } catch (err) {
            console.log(err);
        }
    }

    updateChanged() {
        if (!this._popper) return;
        this._popper.update();
    }
}
